import React, { useCallback } from "react";
import { DropResult } from "react-beautiful-dnd";
import {
  Container,
  Vertical,
  Title,
  Link,
  Preview,
  Background,
  Label,
  MenuLink,
  QRBox,
  Description,
} from "./style";
import { useStores } from "../../contexts/store_context";
import { IItem } from "../../components/draggable_list/types";
import DraggableList from "../../components/draggable_list";
import Tabs from "../../components/tabs";
import { IComponentProps } from "./types";
import event from "../../event";

// a little function to help us with reordering the result
const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DigitalMenuPage: React.FC<IComponentProps> = ({ t }: IComponentProps) => {
  const stores = useStores();

  const company = stores?.digitalMenuPageStore.company;
  const categories_by_priority = stores?.digitalMenuPageStore.categories_by_priority;
  const menu_url = `https://motion.menu/shop/${company?.slug}`;

  const onCategoryDragEnd = useCallback(
    async (result: DropResult) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      if (categories_by_priority !== undefined) {
        const new_categories = reorder(
          categories_by_priority,
          result.source.index,
          result.destination.index
        );

        await stores?.digitalMenuPageStore.company.update({
          category_priority: new_categories.join(","),
        });

        await stores?.digitalMenuPageStore.loadCategories();
      }
    },
    [categories_by_priority, stores?.digitalMenuPageStore]
  );

  const onProductDragEnd = useCallback(
    async (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      if (stores?.digitalMenuPageStore.products === undefined) {
        return;
      }

      const reordered_products = reorder(
        stores.digitalMenuPageStore.products,
        result.source.index,
        result.destination.index
      );

      reordered_products.forEach(async (product, index) => {
        await product.update({
          priority: index,
        });
      });
    },
    [stores?.digitalMenuPageStore.products]
  );

  const menu_criteria_tabs = [
    { value: "categories", displayed_name: t("digital-menu-page.sort-by-categories") },
    {
      value: "products",
      displayed_name: t("digital-menu-page.sort-by-products"),
    },
  ];
  // TODO: Add tab for criterias
  const updateMenuSortCriteria = useCallback((value: string) => {
    event.track("digital-menu-page.menu-order-tabs.click", { value });
  }, []);

  const draggable_categories: IItem[] = (categories_by_priority || [])?.map((category) => ({
    value: category,
    displayed_value: category,
  }));

  const draggable_products: IItem[] = (
    stores?.digitalMenuPageStore.products || []
  )?.map((product) => ({
    value: String(product.id),
    displayed_value: product.name,
  }));

  return (
    <Container>
      <Vertical>
        <Title>{t("digital-menu-page.title")}</Title>
        <Description>
          {t("digital-menu-page.description")}{" "}
          <Link to="/products">{t("here")}</Link>
        </Description>

        <Label>
          {t("digital-menu-page.menu-page-link")}{" "}
          <MenuLink href={menu_url} target="_blank">
            {t("link")}!
          </MenuLink>
        </Label>

        <Description>
          <Label>{t("digital-menu-page.qr-link")}</Label>
        </Description>

        <QRBox>
          <a
            href={`https://api.qrserver.com/v1/create-qr-code/?data=${menu_url}`}
            download=""
            target="_blank"
          >
            <img
              src={`https://api.qrserver.com/v1/create-qr-code/?data=${menu_url}&size=100x100`}
              alt=""
              title=""
            />
          </a>
        </QRBox>

        <br />

        <Label>{t("digital-menu-page.category-priority-title")}</Label>
        <Description>
          {t("digital-menu-page.category-priority-description")}
        </Description>

        <Tabs tabs={menu_criteria_tabs}>
          {(active_tab: string) =>
            active_tab == "categories" ? (
              <DraggableList items={draggable_categories} onDragEnd={onCategoryDragEnd} />
            ) : (
              <DraggableList
                items={draggable_products}
                onDragEnd={onProductDragEnd}
              />
            )
          }
        </Tabs>
      </Vertical>

      <Vertical>
        <Preview src={menu_url}></Preview>
      </Vertical>
    </Container>
  );
};

export default DigitalMenuPage;
