import React, { useCallback, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import Component from "./component";
import { IComponentProps } from "./types";
import event from "../../event";

const withValidated =
  (
    InnerComponent: React.ComponentType<
      Omit<IComponentProps, "t" | "i18n" | "tReady">
    >,
  ): React.FC =>
  () => {
    const {
      loginWithRedirect,
      logout,
      isAuthenticated,
      getAccessTokenSilently,
    } = useAuth0();

    const onSignOutClick = useCallback(async () => {
      logout();
      event.reset_identity();
      window.location.reload();
    }, []);

    const onSignInClick = useCallback(async () => {
      loginWithRedirect();
    }, []);

    return (
      <InnerComponent
        isValidated={isAuthenticated}
        onSignOutClick={onSignOutClick}
        onSignInClick={onSignInClick}
      />
    );
  };

export default withValidated(withTranslation()(Component));
