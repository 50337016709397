import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignInPage from "../pages/sign_in_page";
import TablesPage from "../pages/tables_page";
import ProductListPage from "../pages/product_list_page";
import ReviewListPage from "../pages/review_list_page";
import OrderListPage from "../pages/order_list_page";
import DashboardPage from "../pages/dashboard_page";
import LedgerListPage from "../pages/ledger_list_page";
import ProfilePage from "../pages/profile_page";
import PrivateRoute from "./private-route";
import DigitalMenuPage from "../pages/digital_menu_page";
import BillingPage from "../pages/billing_page";

const Router: React.FunctionComponent = () => {
  return (
    <>
      <BrowserRouter>
        <Switch>
          <PrivateRoute exact path="/tables">
            <TablesPage />
          </PrivateRoute>
          <PrivateRoute exact path="/products">
            <ProductListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/reviews">
            <ReviewListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/orders">
            <OrderListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/ledgers">
            <LedgerListPage />
          </PrivateRoute>
          <PrivateRoute exact path="/dashboard">
            <DashboardPage />
          </PrivateRoute>
          <PrivateRoute exact path="/profile">
            <ProfilePage />
          </PrivateRoute>
          <PrivateRoute exact path="/digital-menu">
            <DigitalMenuPage />
          </PrivateRoute>
          <PrivateRoute exact path="/billing">
            <BillingPage />
          </PrivateRoute>
          <Route path={["/", "/sign_in"]}>
            <SignInPage />
          </Route>
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default Router;
