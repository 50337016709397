import React from "react";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";
import Component from "./component";
import event from "../../event";
import { useAuth0 } from "@auth0/auth0-react";

export const withLogOut =
  (InnerComponent: React.ComponentType<any>): React.FC =>
  () => {
    const { logout } = useAuth0();

    const onSignOutClick = React.useCallback(async () => {
      logout();
      event.reset_identity();

      window.location.reload();
    }, []);

    return <InnerComponent onSignOutClick={onSignOutClick} />;
  };

export default withLogOut(withRouter(withTranslation()(Component)));
