export default {
  translation: {
    active: "Active",
    "already-subscribed": "Successfully subscribed",
    "Are you sure?": "Are you sure?",
    "billing-page": {
      description:
        "Providing valueable content to customers increases visibility of your products and brand. You can refund and cancle the plan whenever you want.",
      title: "Create a place that your customers love",
      pro: {
        title: "Pay first and get 50% discount",
        "discounted-price":
          "Special offer lasts for a few days only, after that price will be $ 25.98",
      },
    },
    Cancel: "Cancel",
    category: "Category",
    category: "Category",
    "check-email-page": {
      description:
        "Check your email account, we have sent you a confirmation email. You should first activate your account before start using Motion Menu.",
      "sign-in": "Have you activated your account? Please click",
      title: "Thank you! You are part of the community",
    },
    "click here": "click here",
    code: "code",
    Complete: "Complete",
    Cost: "Cost",
    cost: "cost",
    "costTag-description":
      "Analyze what represents more expenses for your business",
    "costTag-title": "Cost by tags",
    "count-description": "Here you can find you most sold products.",
    "count-title": "Most Popular Products",
    Created: "Created",
    created: "Created",
    Dashboard: "Dashboard",
    "dashboard-page": {
      description:
        "Motion Menu is constantly making better dashboard page. Mesure what metters and improve quality.",
      title: "This is your dashboard",
    },
    day: {
      0: "Monday",
      1: "Tuesday",
      2: "Wednesday",
      3: "Thursday",
      4: "Friday",
      5: "Saturday",
      6: "Sunday",
    },
    delete: "Delete",
    Delete: "Delete",
    description: "Description",
    Dicsount: "Dicsount",
    "digital-menu": "Digital menu",
    "digital-menu-page": {
      description:
        "Letters on a white paper is not enough. Fill product catalouge and try QR digital menu. When you provide more contents, clients get more value! You can edit menu items ",
      "menu-page-link": "Here is your menu page",
      "product-priority-description":
        "You would want to move up products with content that you want to highlight.",
      "qr-link": "Click QR code and download image to print out.",
      "sort-by-products": "Sort by products",
      "sort-by-categories": "Sort by categories",
      "category-priority-description":
        "Products with higher order will be shown first. This also changes the menu page order. Products without a tag will be shown last.",
      "category-priority-title": "Change which products are shown first!",
      title: "100% off if you send us photo of your business with printed QR!",
    },
    "dont-have-account": "Don't have an account?",
    "Drop it here!": "Drop it here!",
    "dropzone content": "Drop some files here, or click to select files",
    "editable-cells": {
      boolean: {
        false: "false",
        true: "true",
      },
      tag: {
        available: "Available",
        empty: "No tags",
        input: "Create or seach tag",
        new: "Create new",
        selected: "Selected",
      },
    },
    email: "Email",
    "empty-tables":
      "There is no table here. Please add new table to get started!",
    "Failed to reset password": "Failed to reset password",
    "Failed to sign in": "Failed to sign in",
    "Failed to sign up": "Failed to sign up",
    "Failed to update password": "Failed to update password",
    "File type not accepted, sorry!": "File type not accepted, sorry!",
    "First click new order to fill with items":
      "First click new order to fill with items",
    "floors-tabs": {
      delete: "Do you want to delete floor",
    },
    "Forgot your password?": "Forgot your password?",
    "forgot-password": "Did you forget your password?",
    friday: "Friday",
    "Go back to": "Go back to",
    "Go To Store": "Go To Store",
    "google-sign-in-button": {
      failure: "Failed to sign in",
      success: "Signed in successfully",
    },
    "got-suspended": "You got suspended",
    here: "here",
    home: "home",
    inactive: "Inactive",
    income: "income",
    index: "Index",
    "item-quantity-modal": {
      description: "How many do you want to add?",
      submit: "Confirm",
    },
    "ledger-form": {
      "billed-at": "Billed at",
      bread: "bread",
      coca: "coca",
      created: "Successfully created ledger",
      electricity: "electricity",
      failed: "Failed to create ledger",
      ham: "ham",
      material: "material",
      milk: "milk",
      salary: "salary",
      soda: "soda",
      submit: "Submit",
      suger: "suger",
    },
    "ledger-list-page": {
      description: "ledger description",
      title: "Welcome!",
    },
    "ledger-list-page": {
      description:
        "Knowing what are the costs and analyzing your income is important. First create your ledger. You first select income or cost. It is highly recommended to add tags, so you can track and distinguish ledgers laster.",
      title: "Check recent incomes and costs!",
    },
    Ledgers: "Ledgers",
    Menu: "Menu",
    monday: "Monday",
    month: "Month",
    "Motion Menu is a place that connects you with your clients!":
      "Motion Menu is a place that connects you with your clients!",
    Name: "Name",
    navbar: {
      billing: "Billing",
      dashboard: "Dashboard",
      "digital-menu": "Digital Menu",
      ledgers: "Ledgers",
      orders: "Orders",
      products: "Products",
      profile: "Profile",
      "sign-out": "Sign out",
      tables: "Tables",
    },
    "No actions": "No actions",
    "No products found": "No products found",
    "no-confirmation-email": "No confirmation email received? click ",
    "no-result": "No result found",
    options: "options",
    "order-form": {
      cancel: "Cancel",
      complete: "Complete",
      "complete-failed": "Could not mark as completed",
      completed: "Order is completed",
      discount: "Discount",
      item: {
        update: {
          failure: "failed to update item count",
          success: "item count was updated",
        },
      },
      "no-items": "No items",
      print: "Print",
      "print-code": "Print code",
      subtotal: "Subtotal",
      total: "Total",
    },
    "order-list": {
      created: "Created",
      dicsount: "Dicsount",
      index: "Index",
      items: "Items",
      name: "Name",
      "no-result": "No result found",
      status: "Status",
      subtotal: "Subtotal",
      total: "Total",
    },
    "order-list-page": {
      description: "Each button fetch all orders for selected date range",
      title:
        "Here you can find completed, canceled orders. Change daterange to explore more older orderes.",
    },
    Orders: "Orders",
    password: "Password",
    "password-confirmation": "Password Confirmation",
    "payment-method-table": {
      create: "Create",
      default: "Is default?",
      "default-input": {
        failed: "Failed to update default payment method",
        success: "Default payment method was updated",
      },
      delete: "Delete",
      "delete-confirm": "Are you sure?",
      name: "name",
      "name-input": {
        failed: "Name was invalid.",
        success: "Name was updated",
      },
    },
    plan: {
      dashboard: "Get better insights with dashboard",
      "digital-menu": "Get QR code for digital menu",
      free: "All free plan features are included",
      ledgers: "Manage incomes and expenses",
      orders: "Create orders",
      products: "Create products and upload images",
      tables: "Manage tables",
      upgrade: "Upgrade Plan",
      video: "Upload video and images for products",
    },
    "Please wait for redirection": "Please wait for redirection",
    "product-list": {
      column: {
        code: "Code",
        created: "Created",
        delete: "Delete",
        description: "Description",
        edit: "Edit",
        images: "Images",
        name: "Name",
        options: "Options",
        price: "Price",
        published: "Published",
        tag: "Tag",
      },
      "image-form": {
        "maximum-image":
          "Maximum file size is 5 MB. Your image will be displayed on the digital menu if there is no video. If you uploaded multiple images then users will be able to see images by clicking the product.",
        "maximum-videos":
          "Maximum file size is 2 MB. Your video will be displayed on the digital menu first. MP4 is recommended file format.",
        "no-images-found": "No images found",
        "no-videos-found": "No videos found",
        remove: "Remove",
        upgrade: {
          description: "Get all features after subscription",
          title: "Subscribe",
        },
        "uploaded-images": "Uploaded images",
        "uploaded-videos": "Uploaded videos",
      },
      "no-products-found": "No products found",
      product: {
        created: "Created",
        delete: "Delete",
        images: "Images",
        options: "Options",
        upload: {
          failed: "Failed to upload",
          loading: "Uploading",
          success: "Uploaded successfully",
        },
      },
    },
    "product-option-group-form": {
      "empty-options": "You don't have any options",
      "update-failure": "Failed to add product option",
      "update-success": "Option was added",
    },
    "product-option-groups-form": {
      "create-group": "Create group",
      description:
        "Here you can create variants of the product. For example, hamburger can have options like french fries and coca cola. You can make production option as mandatory.",
      "edit-me": "Edit me",
      "is-mandatory": "Is mandatory?",
      "max-groups-limit": "You can add maximum {{limit}} groups",
      "max-options-limit": "You can add maximum {{limit}} options",
      "new-option": "New option",
      "selection-limit": "User can select",
      title: "Product Options",
    },
    "product-options": {
      "new-group": "New group",
    },
    "product-table": {
      category: "Category",
      code: "Code",
      created: "Created",
      delete: {
        button: "Delete",
        confirm: "Do you want to delete the product?",
        failure: "Failed to delete product",
        success: "Product was deleted",
        title: "Delete {{count}}",
      },
      description: {
        placeholder: "Add a description",
        title: "Description",
      },
      edit: "Edit",
      images: "Images",
      name: "Name",
      options: "Options",
      price: "Price",
      published: "Published",
      tags: "Tags",
      update: {
        failure: "Failed to update product",
        success: "Product was updated",
      },
    },
    products: "Products",
    "products-page": {
      "5-new-product-button": "+5 New products",
      "5-new-product-button": "+5 New products",
      description:
        "Create new product, change name and price here. You can also hide any products.",
      "new-product-button": "New product",
      steps: {
        1: "Press this button to create new product. No need to enter product name or price.",
        2: "Edit product name directly. Click the text and you will be able to update the name.",
        3: "(Optional) product code is unique identifier of the product. It is not visible for the customers. (example) PD-12",
        4: "Product description is shown to the customer through the digital menu. Describe your product and give more content and value to the customer.",
        5: "Product price can have two decimal points.",
        6: "Make your product public or hidden in the digital menu. Click the text to publish or hide. This changes only the visiblity in the consumer side application. You can use and add hidden product to the order if you want.",
        7: "Add tag to your product. (example) dinner, drink and coffee. Our digital menu is organized by the tag. This data can also be used in analytics.",
        8: "Create product options. One use case of this feature is the hamburger. You can create and organize variants here.",
        9: "Add product images and videos here. The maximum size is 5 MB. Keep images and videos as small as possible. Try this site! https://www.imgonline.com.ua/eng/compress-image-size.php",
        10: "You can remove your product by clicking this button.",
      },
      title: "This is where you manage your products",
    },
    "profile-form": {
      description: {
        description: "Description is shown on the digital menu landing page.",
        long: "Description is too long.",
        title: "Shop description",
      },
      failure: "Failed to update the information",
      instagram: "Instagram",
      instagram: {
        title: "Instagram",
      },
      "instagram-long": "Instagram URL is too long",
      locale: {
        title: "Language",
      },
      name: {
        description:
          "Enter your business name. URL will be generated based on this value.",
        short: "Name is too short.",
        title: "Shop name",
      },
      Profile: "Profile",
      submit: "Save",
      success: "Profile was updated",
    },
    "profile-page": {
      "daily-report": {
        "active-error": "Failed to update daily report",
        "active-updated": "Daily report was updated successfully",
        "close-time-error": "Failed to update close time",
        "close-time-label": "The shop close at",
        "close-time-updated": "The close time was updated",
        "duration-error": "Failed to update duration",
        "duration-label-1": "Collect the last",
        "duration-label-2": "hours of data",
        "duration-updated": "The duration was updated",
        info: "The daily report will be sent {{sent_time}} and it will include data from {{start_time}} to {{end_time}}",
        title: "Daily Report Settings",
        trigger: {
          failure: "Failed to send the report",
          success: "The report was sent successfully",
          title: "Test",
        },
      },
      description: "Update business profile and application settings.",
      free: {
        description: "Restricted features.",
        title: "14 days free trial",
      },
      "payment-methods": {
        create: "Create",
        "default-name": "New",
        description:
          "You can add or remove payment method. The default payment method gets assigned to the newly created order.",
        title: "Payment methods",
      },
      pro: {
        description:
          "Purchase for a better user experience. We build best digital menu for your shop.",
        title: "Unlimited plan",
      },
      title: "Settings",
    },
    "re-send-confirmation-email": "Re-send",
    reactivate: "Reactivate",
    "resend-confirmation-email-form": {
      failure:
        "Could't send email, please contact support team via live chat or email.",
      success: "We sent confirmation email!",
    },
    "resend-confirmation-email-page": {
      title: "Resend confrimation email",
    },
    "reset-password": "Reset password",
    "reset-password-confirmation-page": {
      title: "Please wait for redirection",
    },
    "reset-password-form": {
      submit: "Reset Password",
    },
    "reset-password-page": {
      title: "Forgot your password?",
    },
    "review-list-page": {
      action: {
        featured: "Featured",
        hide: "Hide",
        publish: "Publish",
      },
      featured: {
        "cancel-button": "Cancel featured",
        count: "You have {{count}} featured reviews",
        description:
          "Featured reviews are shown first. Make featured reviews and let users know about your shop. (maximum 3 reviews)",
        title: "Featured reviews",
      },
      notification: {
        error: "Something went wrong",
        featured: "Review became featured",
        hidden: "Your review was hidden",
        published: "Your review was published",
      },
      reviews: {
        description:
          "Publish or hide user reviews. Users won't see hidden reviews. All unmoderated reviews are shown under the New tab.",
        "no-hidden-reviews":
          "No hidden reviews found. First check your new reviews tab. 😮",
        "no-new-reviews":
          "There are 0 reviews. You will see reviews once users submit review. 😀",
        "no-published-reviews":
          "No published reviews found. First check your new reviews tab. 😮",
        title: "Here are fresh reviews",
      },
      tab: {
        hidden: "Hidden",
        new: "New",
        published: "Published",
      },
    },
    reviews: "reviews",
    "sales-description": `Here you can check how much you sold given time frame. Compare this with other weeks and see if is positively moving.`,
    "sales-title": "Check out your sale records",
    saturday: "Saturday",
    "Search by name": "Search by name....",
    "select-dates": "Select dates",
    "Set new password": "Set new password",
    "Sign In to Motion Menu": "Sign In to Motion Menu",
    "Sign out": "Sign out",
    "Sign Up in Motion Menu": "Sign Up in Motion Menu",
    "sign-in-form": {
      "sign-in-as-company": "shop owner",
      "sign-in-as-employee": "employee",
      submit: "Sign In",
    },
    "sign-in-page": {
      description: "Mtion Menu is a place that connects you with your clients!",
      releases: "Check out our upcoming features!",
      title: "More content more value",
    },
    "sign-up": "Sign up",
    "sign-up-form": {
      "shop-name": "Shop Name",
      submit: "Sign Up!",
      "your-shop-name": "Your shop name",
    },
    "sign-up-page": {
      "already-have-account": "Already have an account?",
      description:
        "Motion Menu is a place that connects you with your clients!",
      "sign-in": "Sign In",
      title: "Sign up to Motion Menu",
    },
    "Signed in successfully": "Signed in successfully",
    "Signed up successfully": "Signed up successfully",
    "start-tour": " Start tour",
    Status: "Status",
    subscribe: "Subscribe",
    Subtotal: "Subtotal",
    sunday: "Sunday",
    suspend: "Suspend",
    "Table is empty": "Table is empty",
    "table-list": {
      "is-empty": {
        description: "You don't have any orders yet.",
        title: "No orders",
      },
      "new-order-button": "New order",
    },
    Tables: "Tables",
    "tables-page": {
      "add-table": "Add Table",
      description:
        "You can create new floor with plus button. You can create new table for the current floor.",
      "start-moving-tables": "Start moving tables",
      steps: {
        1: "Press the button to create a new table",
        2: "Edit the name directly. You can edit it by clicking on the text.",
        3: "You can delete the table by displaying the menu",
        4: "Create a new order where you can add the products requested by customers",
        5: "Click here to be able to position the tables as necessary, click again to stop moving them",
        6: "You can add new floors or sectors if necessary",
        7: "Edit the name of the floor or sector. You can edit it by clicking on the text",
        8: "Display the floors menu to be able to eliminate them if necessary",
      },
      "stop-moving-tables": "Stop moving tables",
      title: "Manage your tables and floors here",
    },
    tags: "Tags",
    "This Month": "This Month",
    "This Week": "This Week",
    thursday: "Thursday",
    "to sign in": "to sign in",
    Today: "Today",
    Total: "Total",
    total: "Total",
    "trial-modal": {
      "attract-more-link": "Contact us",
      "attract-more-title":
        "Would you like to attract more customers with next level images and videos?",
      description:
        "You can use all features for free. We wil let you know when your trial ends.",
      dismiss: "Dismiss for a while",
      "get-access-link": "${{ price }}",
      "get-access-title": "Get lifetime access ",
      title: "You are in 14 days free trial!",
    },
    tuesday: "Tuesday",
    "unauthorized-page": {
      description: "Please, request access to your manager.",
      title: "Oops, you are not authorized",
    },
    undo: "undo",
    "update-password-form": {
      submit: "Update Password",
    },
    "update-password-page": {
      title: "Set new password",
    },
    "Uploaded images": "Uploaded images",
    validations: {
      email: {
        long: "Email is too long",
        required: "Email is required",
        short: "Email is too short",
        valid: "Email must be valid",
      },
      name: {
        long: "Name is too long",
        required: "Name is required",
        short: "Name is too short",
      },
      password: {
        long: "Password is too long",
        match: "Password does not match",
        required: "Password is required",
        short: "Password is too short",
      },
    },
    "We have sent you a reset password email!":
      "We have sent you a reset password email!",
    wednesday: "Wednesday",
    "Welcome to motion menu. Ultimate tool get more clients connected!":
      "Welcome to motion menu. Ultimate tool get more clients connected!",
    Yesterday: "Yesterday",
    "You don't have any uploaded images": "You don't have any uploaded images",
    "You don't have any videos": "You don't have any videos",
    "You have updated password successfully!":
      "You have updated password successfully!",
  },
};
