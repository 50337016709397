import { makeAutoObservable } from "mobx";
import {
  ICompany,
  IUpdateParams,
  IUpdateResponse,
} from "../api/profile_client";
import { IUpdateResponse as ISubscriptionUpdateResponse } from "../api/profile_client";
import { profileClient, subscriptionClient } from "../api";

class Company {
  id!: number;
  name!: string;
  slug!: string;
  description!: string;
  short_description!: string;
  category_priority!: string;
  instagram!: string;
  subscription_id!: string;
  subscription_plan_id!: string;
  subscription_plan!: string;
  subscription_status!: string;
  current_plan!: string;
  current_plan_status!: string;
  time_zone!: string;
  locale!: string;

  isLoading = false;

  constructor(params: ICompany) {
    makeAutoObservable(this);
    this.refresh(params);
  }

  refresh(json: ICompany): void {
    this.id = json.id;
    this.name = json.name;
    this.slug = json.slug;
    this.description = json.description;
    this.short_description = json.short_description;
    this.category_priority = json.category_priority;
    this.instagram = json.instagram;
    this.subscription_id = json.subscription_id;
    this.subscription_plan = json.subscription_plan;
    this.subscription_plan_id = json.subscription_plan_id;
    this.subscription_status = json.subscription_status;
    this.current_plan = json.current_plan;
    this.current_plan_status = json.current_plan_status;
    this.locale = json.locale;
    this.time_zone = json.time_zone;
  }

  *update(
    params: IUpdateParams
  ): Generator<Promise<IUpdateResponse>, IUpdateResponse, IUpdateResponse> {
    try {
      this.isLoading = true;

      const response = yield profileClient.update(params);
      this.refresh(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *suspend(): Generator<
    Promise<ISubscriptionUpdateResponse>,
    ISubscriptionUpdateResponse,
    ISubscriptionUpdateResponse
  > {
    try {
      this.isLoading = true;

      const response = yield subscriptionClient.suspend();
      this.refresh(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  *activate(): Generator<
    Promise<ISubscriptionUpdateResponse>,
    ISubscriptionUpdateResponse,
    ISubscriptionUpdateResponse
  > {
    try {
      this.isLoading = true;

      const response = yield subscriptionClient.activate();
      this.refresh(response);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  get hasActivePro(): boolean {
    if (this.current_plan === "pro" && this.current_plan_status === "active") {
      return true;
    } else if (
      this.subscription_plan === "PRO" &&
      this.subscription_status === "ACTIVE"
    ) {
      return true;
    }

    return false;
  }
}

export default Company;
