import { AxiosInstance } from "axios";

interface IValidateResponse {
  success: boolean;
}

class AuthClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async validate(): Promise<IValidateResponse> {
    const response = await this.restClient.post(
      "/api/v1/companies/auth/validate",
    );
    return response.data;
  }
}

export default AuthClient;
