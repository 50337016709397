import React from "react";
import Cookies from "../lib/cookies";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
import LoadingPage from "../pages/loading_page";
import Navbar from "../components/navbar";
import { useAuth0 } from "@auth0/auth0-react";
import { authClient } from "../api";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function PrivateRoute({ children, ...rest }: RouteProps) {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  // const accessToken = Cookies.get("access-token");

  const [isLoading, setLoading] = React.useState(true);
  const [isValidated, setValidated] = React.useState(false);

  React.useEffect(() => {
    async function validateCredentials() {
      try {
        if (isAuthenticated) {
          const access_token = await getAccessTokenSilently();

          Cookies.set("access-token", access_token, {
            expires: 3,
          });

          const { success } = await authClient.validate();
          console.log(success);
        }

        setValidated(isAuthenticated);
      } catch (error) {
        console.error(error);
        setValidated(false);
      } finally {
        setLoading(false);
      }
    }

    validateCredentials();
  }, [isAuthenticated]);

  const renderRedirect = (props: RouteComponentProps) => {
    // wait until isLoading is set to false
    if (isLoading) {
      return <LoadingPage />;
    }

    return (
      (isAuthenticated === false || isValidated === false) && (
        <Redirect
          to={{
            pathname: "/sign_in",
            state: { from: props.location },
          }}
        />
      )
    );
  };

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={(props) =>
        isAuthenticated && isValidated && !isLoading ? (
          <>
            <Navbar />
            {children}
          </>
        ) : (
          renderRedirect(props)
        )
      }
    />
  );
}

export default PrivateRoute;
