import React from "react";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  Container,
  LeftContainer,
  RightContainer,
  Header,
  Description,
  Link,
  SignOut,
} from "./style";
import { IComponentProps } from "./types";
import Logo from "../../controls/logo";
import LanguageSelect from "../../components/language_select";
import Button from "../../controls/button";

const SignInPage: React.FunctionComponent<IComponentProps> = ({
  isValidated,
  onSignOutClick,
  onSignInClick,
  t,
}) => {
  return (
    <>
      <Container>
        <LeftContainer>
          <Logo />
          <Header>{t("sign-in-page.title")}</Header>
          <Description>{t("sign-in-page.description")}</Description>
          <LanguageSelect />
        </LeftContainer>

        {isValidated ? (
          <RightContainer>
            <Link to="/products">{t("Go To Store")}</Link>
            <SignOut onClick={onSignOutClick}>{t("Sign out")}</SignOut>
          </RightContainer>
        ) : (
          <RightContainer>
            <Button onClick={onSignInClick} variant="blue">
              {t("Sign In")}
            </Button>
          </RightContainer>
        )}
      </Container>
    </>
  );
};

export default SignInPage;
