import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from "notistack";
import createStore from "./stores";
import { StoresContext } from "./contexts/store_context";
import Router from "./router";
import { theme } from "./theme";
import Footer from "./components/footer";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";
import { Auth0Provider } from "@auth0/auth0-react";

function App(): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: "https://motion.menu",
            scope: "profile",
          }}
          useRefreshTokens={true}
          cacheLocation="localstorage"
        >
          <FlagsmithProvider
            options={{
              environmentID:
                process.env.REACT_APP_FLAGSMITH_CLIENT_ENV_KEY || "",
            }}
            flagsmith={flagsmith}
          >
            <StoresContext.Provider value={createStore()}>
              <Router />
              <Footer />
            </StoresContext.Provider>
          </FlagsmithProvider>
        </Auth0Provider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
