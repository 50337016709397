import { AxiosInstance } from "axios";
import { IProductOptionGroup } from "./product_option_groups_client";
import { IPageMeta } from "./types";

export interface IVideo {
  id: number;
  public_id: string;
  created_at: string;
  updated_at: string;
}

export interface IImage {
  id: number;
  public_id: string;
  image_type: string;
  created_at: string;
  updated_at: string;
}

export interface ITag {
  id: number;
  name: string;
}

export interface IProduct {
  id: number;
  name: string;
  description: string;
  stock: number;
  published: boolean;
  price: number;
  code: string;
  images: IImage[];
  videos: IVideo[];
  tag_list: string[];
  product_option_groups: IProductOptionGroup[];
  priority: number;
  created_at: string;
  category: string;
  updated_at: string;
  deleted_at?: string;
}

export interface IUpdateParams
  extends Partial<
    Omit<
      IProduct,
      "id" | "tag_list" | "created_at" | "deleted_at" | "updated_at"
    >
  > {
  tag_list?: string;
}

export interface ICreateParams extends IUpdateParams {
  name: string;
}

export interface IIndexResponse {
  data: Record<string, IProduct>;
  ids: string[];
  meta: IPageMeta;
}

class ProductsClient {
  restClient!: AxiosInstance;

  constructor(restClient: AxiosInstance) {
    this.restClient = restClient;
  }

  async index(page: number, per_page: number): Promise<IIndexResponse> {
    const response = await this.restClient.get("/api/v1/companies/products", {
      params: {
        page,
        per_page,
      },
    });
    return response.data;
  }

  async create(body: ICreateParams): Promise<IProduct> {
    const response = await this.restClient.post("/api/v1/companies/products", {
      product: body,
    });
    return response.data;
  }

  async update(id: number, body: IUpdateParams): Promise<IProduct> {
    const response = await this.restClient.put(
      `/api/v1/companies/products/${id}`,
      { product: body }
    );
    return response.data;
  }

  async delete(id: number): Promise<void> {
    const response = await this.restClient.delete(
      `/api/v1/companies/products/${id}`
    );
    return response.data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async add_image(product_id: number, data: any): Promise<IProduct> {
    const response = await this.restClient.post(
      `/api/v1/companies/products/${product_id}/images`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }

  async remove_image(product_id: number, image_id: number): Promise<IProduct> {
    const response = await this.restClient.delete(
      `/api/v1/companies/products/${product_id}/images/${image_id}`
    );

    return response.data;
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async add_video(product_id: number, data: any): Promise<IProduct> {
    const response = await this.restClient.post(
      `/api/v1/companies/products/${product_id}/videos`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response.data;
  }

  async remove_video(product_id: number, video_id: number): Promise<IProduct> {
    const response = await this.restClient.delete(
      `/api/v1/companies/products/${product_id}/videos/${video_id}`
    );

    return response.data;
  }

  // GET /products/categories
  // It returns a list of all product categories.
  // [{ id: 1, name: "coffee" }]
  async all_categories(sort_by_priority = false): Promise<ITag[]> {
    const response = await this.restClient.get(
      "/api/v1/companies/products/categories",
      {
        params: {
          sort_by_priority: sort_by_priority,
        },
      }
    );

    return response.data;
  }
}

export default ProductsClient;
